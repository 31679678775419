import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["pagination"];

    connect() {
        this.updateURL();
    }

    updateURL() {
        const links = this.paginationTarget.querySelectorAll("a");

        links.forEach(link => {
            link.addEventListener("click", (event) => {
                event.preventDefault();

                const url = new URL(link.href);
                const params = new URLSearchParams(window.location.search);

                // Preserve existing params without duplicates
                url.searchParams.forEach((value, key) => {
                    if (params.has(key) && key.endsWith("[]")) {
                        const existingValues = params.getAll(key);
                        if (!existingValues.includes(value)) {
                            params.append(key, value);
                        }
                    } else {
                        params.set(key, value);
                    }
                });

                // Manage the `page` parameter
                const page = url.searchParams.get("page");

                if (page === "1") {
                    params.delete("page");
                } else {
                    params.set("page", page);
                }

                const newUrl = `${window.location.pathname}${params.toString() ? `?${params.toString()}` : ''}`;
                history.pushState({}, "", newUrl);
                Turbo.visit(newUrl);
            });
        });
    }
}
