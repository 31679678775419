import {Controller} from "@hotwired/stimulus"
import TomSelect from "tom-select";

/*
 * Usage
 * =====
 *
 * add data-controller="tom-select" to the select element
 *
 * Example:
 * <select data-controller="tom-select"
 *         data-tom-select-url-value="autocompletes_instructors_url"
 *         data-tom-select-label-field-value="name"
 *         data-tom-select-value-field-value="id"
 *         data-tom-select-placeholder-value="Vyberte..."
 *         data-tom-select-submit-on-change-value="true">
 * </select>
 *
 * ERB Example:
 *
 * <%=
 *    form.collection_select :assigned_to_id_in, User.by_a.all_instructors, :id, :full_name,
 *                           { multiple: true },
 *                           {
 *                             class: 'form-select',
 *                             data: {
 *                               controller: 'tom-select',
 *                               tom_select_url_value: autocompletes_instructors_url,
 *                               tom_select_label_field_value: 'name',
 *                               tom_select_value_field_value: 'id',
 *                               tom_select_placeholder_value: 'Vyberte...',
 *                               tom_select_submit_on_change_value: true
 *                             }
 *                           }
 *  %>
 */

export default class extends Controller {
    // Define static value to fetch the URL from HTML data attributes.
    static values = {url: String, valueField: String, labelField: String, placeholder: String, submitOnChange: Boolean};

    // Triggered when the Stimulus controller is connected to the DOM.
    connect() {
        this.initializeTomSelect();

        // Trigger the form submission when the dropdown value changes.
        this.element.addEventListener('change', this.submit.bind(this));
    }

    // Triggered when the Stimulus controller is removed from the DOM.
    disconnect() {
        this.destroyTomSelect();
    }

    // Initialize the TomSelect dropdown with the desired configurations.
    initializeTomSelect() {
        // Return early if no element is associated with the controller.
        if (!this.element) return;

        // Construct URL for fetching data which comes from the static value.
        // https://tom-select.js.org/examples/remote/
        const url = `${this.urlValue}`;

        // Extract the valueField, labelField, and placeholder from the static values.
        // Could be string or array of strings.
        const valueField = this.valueFieldValue || 'id';
        const labelField = this.labelFieldValue || 'name';
        const placeholder = this.placeholderValue || 'Vyberte...';

        this.submitOnChange = this.submitOnChangeValue !== false;

        // Fetch data for the dropdown.
        const fetchData = (search, callback) => {
            const queryUrl = `${url}?q=${encodeURIComponent(search)}`;

            fetch(queryUrl)
                .then(response => response.json())  // Convert response to JSON.
                .then(data => callback(data))       // Send data to TomSelect.
                .catch(() => callback());           // Handle any errors.
        };

        // Define custom rendering for dropdown options.
        // see: https://tom-select.js.org/examples/customization/
        const renderOption = (data, escape) => {
            return `
                    <div>
                      <span class="block">${escape(data.name)}</span>
                    </div>
                  `;
        };

        // Create a new TomSelect instance with the specified configuration.
        // see: https://tom-select.js.org/docs/
        // value, label, search, placeholder, etc can all be passed as static values instead of hard-coded.
        this.select = new TomSelect(this.element, {
            plugins: ['remove_button'],
            labelField: labelField,
            valueField: valueField,
            searchField: labelField,
            // maxItems: 1,
            selectOnTab: true,
            placeholder: placeholder,
            closeAfterSelect: true,
            hidePlaceholder: false,
            preload: true,
            create: false,
            openOnFocus: true,
            highlight: true,
            sortField: {field: labelField, direction: "asc"},
            load: fetchData,
            render: {
                option: renderOption
            }
        });
    }

    submit() {
        // Submit only when the submitOnChangeValue is set to true.
        if (this.submitOnChange) {
            this.element.closest('form').submit();
        }
    }

    // Cleanup: Destroy the TomSelect instance when the controller is disconnected.
    destroyTomSelect() {
        if (this.select) {
            this.select.destroy();
        }
    }
}
